import React, { useEffect, useState } from 'react';
import cn from 'clsx';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';

import TestimonialsSlider from 'components/TestimonialsSlider/TestimonialsSlider';
import NewFaq from '../../components/NewFaq/NewFaq';
import TrustedBy from '../../components/TrustedBy';
import useMediaQuery from '../../hooks/useMediaQuery';

import 'styles/agencies.scss';
import { signUp } from '../../utils/contentProvider/function';

const faqData = [
  {
    q: 'How to use the website feedback tool? ',
    a: (
      <>
        <a
          href="https://web.ruttl.com/signup"
          target="_blank"
          rel="noopener noreferrer">
          Sign up
        </a>
        &nbsp;and create a project, then paste your website URL inside this
        project. Invite your teammates as collaborators by adding their email
        addresses. Once the page loads on ruttl, click to add comments, tag
        other collaborators in comments using ‘@’, and resolve or delete
        comments. You can also add video comments. Switch to edit mode to move
        elements, replace images, edit text, change background colours, modify
        opacities, etc. These edits can be resolved once done, speeding up your
        web review process.
      </>
    ),
  },
  {
    q: 'Do clients need to have an account on ruttl to view projects?',
    a: 'Absolutely not! Once your project is ready to share, simply click the Share button on the project page. In the new screen, click on the ‘Copy link’ text. The shareability toggle should be turned on and the link will be copied to your clipboard. Now all you need to do is share the link with your clients to get their feedback as comments on your project(s).',
  },

  {
    q: 'How many collaborators can I add to a project?',
    a: (
      <>
        In the free version, you have the ability to add up to 5 collaborators
        on a project. However, the limit of collaborators changes as per the
        paid plan that you upgrade to.For more details, check out our
        <Link to="/pricing/">&nbsp;pricing plans</Link>
        &nbsp;.
      </>
    ),
  },
  {
    q: 'Are there any integrations available in ruttl?',
    a: (
      <>
        Currently we have integrated with Jira, Slack, Trello, ClickUp, Zapier,
        asana and Unsplash. We also have our custom webhook, in case you’d like
        to integrate with any other work tool of your preference. In the future,
        we also plan to integrate with many more tools. For more details, please
        visit our
        <Link to="/integrations/">&nbsp;integrations&nbsp;</Link>
        page.
      </>
    ),
  },

  {
    q: 'Is there a public roadmap available?',
    a: (
      <>
        Yes, we have our public roadmap. Check out our
        <a
          href="https://trello.com/b/D9T51EZX"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;Trello board&nbsp;
        </a>
        for the same.
      </>
    ),
  },
  {
    q: 'Can developers access website code after design changes are executed?',
    a: ' After the edits are saved, your developer(s) can see them inside the Activity Panel with precise changes. To make the required changes to your website design, your developers can simply copy the entire altered code under the CSS tab. This can then be pasted directly into the code of live/staging website files.',
  },
  {
    q: 'How do I upgrade my ruttl account? ',
    a: (
      <>
        Please visit the
        <a
          href="https://web.ruttl.com/upgrade"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;Upgrade Account&nbsp;
        </a>
        section. Here you can choose the plan that suits you best, select the
        plan in monthly/annual mode and make the payment. Your account will be
        upgraded once we successfully receive your payment.
      </>
    ),
  },
  {
    q: ` I’d like to schedule a demo with my team, how do I do it?`,
    a: (
      <>
        Please schedule a call with us using
        <a
          href="https://calendly.com/setup-demo-with-ruttl/30min"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;this link.
        </a>
      </>
    ),
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));
const agencyFeatures = [
  {
    id: 0,
    heading: 'Leave comments on live websites',
    description: (
      <>
        {' '}
        Say No to dozens of screenshots, confusions, and inefficient feedback by
        leaving precise feedback directions on live websites. Assign, mention,
        and resolve comments and keep accountability in the loop.
      </>
    ),
    buttonText: 'Comment on Live Website',
    img: '/assets/img/leave-comments-website.jpg',
  },
  {
    id: 1,
    heading: 'Make edits on live websites',
    description: (
      <>
        An upcoming deadline? Instead of having to go to your development team
        for minor changes, get them done easily with Ruttl. Change even the
        tiniest details like font, spacing, and live alignment.
      </>
    ),
    buttonText: 'Edit your Live Website',
    img: '/assets/img/edit-live-website.mp4',
    isVideo: true,
  },
  {
    id: 2,
    heading: 'Get feedback directly from your clients',
    description: (
      <>
        No sign-ups! No log-ins! No email follow-ups! Just send a shareability
        link with your clients and let them write away. They can also tag other
        project stakeholders to assign a task and get the feedback process
        moving faster.
      </>
    ),
    buttonText: 'Collect Feedback For Free',
    img: '/assets/img/guest-commenting.mp4',
    isVideo: true,
  },
  {
    id: 3,
    heading: 'Seamless integration with third-party tools',
    description: (
      <>
        Never miss out on any comment when Ruttl integrates easily with Asana,
        Trello, and Slack. Send comments to Asana, assign due dates, level of
        priorities, add to cards on Trello, and so much more.
      </>
    ),
    buttonText: 'Check Out The Integrations',
    img: '/assets/img/view-comments-trello.mp4',
    isVideo: true,
  },
  {
    id: 4,
    heading: 'Get a quick glance at your project',
    description: (
      <>
        Check everything from website edits, content changes, unresolved
        comments in the Activities panel. Ensure you never lose track of
        important feedback changes.
      </>
    ),
    buttonText: 'Review your project',
    img: '/assets/img/show-activity.png',
  },
  {
    id: 5,
    heading: 'Say yes to great collaboration',
    description: (
      <>
        No more confusion, misunderstandings, missing out on important changes.
        Invite everyone from your design team to your content team to
        collaborate on live projects and meet deadlines. Having real-time
        updates becomes easier than ever with Ruttl.
      </>
    ),
    buttonText: 'Start Collaborating',
    img: '/assets/img/great-collab.png',
  },
  {
    id: 6,
    heading: 'Deliver responsive websites to your clients',
    description: (
      <>
        Quickly switch between desktop and mobile view to keep your client’s
        website updated and responsive across devices.
      </>
    ),
    buttonText: 'Start Reviewing',
    img: '/assets/img/responsive.mp4',
    isVideo: true,
  },
  {
    id: 7,
    heading: 'Keep a track of all website versions',
    description: (
      <>
        Need to go back to a previous version? Want to explore the changes you
        have made to the website? You can easily do that by recording your
        website versions and navigating to them in no time.
      </>
    ),
    buttonText: 'Create Website Versions',
    img: '/assets/img/record-version.png',
  },
  {
    id: 8,
    heading: 'Active CNAME to get your own custom domain',
    description: (
      <>
        own custom domain Want to add a personal touch to your feedback tool?
        You can now create your own custom domain for your design agency.
      </>
    ),
    buttonText: 'Check The CNAME Feature',
    img: '/assets/img/cname.png',
  },
  {
    id: 9,
    heading: 'Impress your client with custom branding',
    description: (
      <>
        Replace Ruttl’s logo and icon with your design agency’s logos and icons.
        You can even choose your preferred primary color and text highlight
        color for the app’s CTAs. Personalization becomes easier with Ruttl.
      </>
    ),
    buttonText: 'Try Custom Branding',
    img: '/assets/img/custom-branding.png',
  },
];

const ForAgencies = () => {
  const [showVideoModal, setVideoModal] = useState(false);
  const isLaptop = useMediaQuery('(max-width: 1800px)');

  function toggleVideoModal() {
    setVideoModal(!showVideoModal);
  }

  useEffect(() => {
    const scrollContents = document.querySelectorAll('.single-sticky-content');
    const scrollImages = document.querySelectorAll('.scroll-img ');

    // let margin;

    // if (window.innerWidth < 1200 && window.innerWidth > 991) {
    //   margin = '-200px';
    // } else if (window.innerWidth < 990) {
    //   margin = '-350px';
    // } else {
    //   margin = '-350px';
    // }

    function handleIntersection(entries) {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          [scrollContents, scrollImages].forEach((e) =>
            e.forEach((el) => el.classList.remove('active')),
          );
          [(scrollContents, scrollImages)].forEach((scrollArr) =>
            scrollArr.forEach((scrollItem) =>
              scrollItem.dataset?.id === entry.target.dataset?.id
                ? scrollItem.classList.add('active')
                : '',
            ),
          );
        }
        return entry;
      });
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.2,
      rootMargin: isLaptop ? '-200px' : '-500px',
    });

    scrollContents.forEach((scrollcontent) => observer.observe(scrollcontent));
  }, []);
  return (
    <Layout hideTopBar>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Ruttl - Best Website Feedback Tool for Web Design Agencies"
            description="Boost your agency's productivity with ruttl, the fastest visual feedback tool. Comment on live websites, PDFs, images, and more effortlessly."
            url="https://ruttl.com/for-agencies/"
          />
          <div
            id="demo-modal"
            style={{ zIndex: 100 }}
            className={cn(['demo-modal', { show: showVideoModal }])}>
            <button
              type="button"
              className="button-unstyled"
              onClick={toggleVideoModal}>
              <img src="/assets/img/close.svg" alt="close" title="close" />
            </button>
            {showVideoModal && (
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/yMyZpJqWRso"
                frameBorder="0"
                title="ruttl demo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
          <main>
            <section className="hero-section" style={{ background: '#fff' }}>
              <div className="container top">
                <div>
                  <h1>
                    Ruttl is the fastest website feedback tool for agencies
                  </h1>
                  <p style={{ marginBottom: '30px' }}>
                    Comment directly on websites, pdfs and images to get
                    feedbacks from clients
                  </p>
                  <Link
                    to="/website-feedback-tool"
                    type="button"
                    className="button">
                    Try it now for free
                  </Link>
                </div>
                <img
                  src="/assets/img/agencies-hero.png"
                  alt="For-agencies"
                  title="For-agencies"
                />
              </div>
            </section>
            <div className="center container">
              <div className="mx-auto agencyHelp">
                <h3>Helping agencies save time and money</h3>
              </div>
              <div className="flex-2 flex flex-wrap align-items-start">
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon">
                        <img
                          loading="lazy"
                          src="/assets/img/pot.svg"
                          alt="flower pot icon"
                          title="flower pot icon"
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Faster Project Delivery</h3>
                        <p>
                          Go live with your web projects within days or weeks as
                          opposed to months!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon" style={{ marginBottom: 8 }}>
                        <img
                          loading="lazy"
                          src="/assets/img/open-webpage.png"
                          alt="security icon"
                          title="security icon"
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Smoother Collaboration</h3>
                        <p>
                          Share the project link & ask your clients to comment
                          directly - no sign-up needed!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="card-single">
                    <div className="card-block">
                      <div className="card-icon">
                        <img
                          loading="lazy"
                          src="/assets/img/pot.svg"
                          alt="flower pot icon"
                          title="flower pot icon"
                        />
                      </div>
                      <div className="card-desc">
                        <h3>Effective Feedback</h3>
                        <p>
                          Save 50% time when compared to emails with screenshots
                          and documents.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button
            type="button"
            style={{ marginTop: '30px' }}
            className="button">
            See how it works
          </button> */}
            </div>
            <div className="container feedback">
              <div className="feebackImage">
                <img
                  loading="lazy"
                  src="/assets/img/afton-negrea.png"
                  alt="For-agencies"
                  title="For-agencies"
                />
                <button
                  type="button"
                  className="playButton"
                  onClick={toggleVideoModal}>
                  <svg width="14" height="14" viewBox="0 0 14 16" fill="none">
                    <path
                      d="M3.742.624C2.411-.223.67.734.67 2.312v11.38c0 1.578 1.742 2.534 3.073 1.687l8.942-5.69a2 2 0 0 0 0-3.375L3.742.624Z"
                      fill="#fff"></path>
                  </svg>
                </button>
              </div>
              <div>
                <h3>
                  “We love ruttl because of its simplicity and the time it
                  saves. With ruttl, we’ve pushed our web projects to live
                  faster, with lesser costs and fewer hurdles in the process. It
                  has really changed the client experience for us and
                  streamlined our entire process.”
                </h3>
                <p>
                  <span>-Afton Negrea,&nbsp;</span>
                  Digital Strategist
                </p>
              </div>
            </div>

            <section className="section-pad">
              <div className="container">
                <div className="flex-row">
                  <div className="col col-6">
                    <div className="sticky-images hidden-mob">
                      {agencyFeatures.map(({ id, img, isVideo }) => (
                        <div
                          data-id={id}
                          className={`scroll-img img${id} ${
                            id === 0 ? 'active' : ''
                          }`}
                          key={id}>
                          {isVideo ? (
                            <video
                              src={img}
                              autoPlay
                              loop
                              muted
                              playsInline></video>
                          ) : (
                            <img src={img} alt="ruttl for agnecies features" />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col col-md-6 col-5 offset-1">
                    <div className="sticky-content-area">
                      {agencyFeatures.map(
                        ({
                          id,
                          heading,
                          description,
                          buttonText,
                          img,
                          isVideo,
                        }) => (
                          <div
                            key={id}
                            className="single-sticky-content"
                            data-id={id}>
                            <div className="visible-mob">
                              {isVideo ? (
                                <video
                                  src={img}
                                  autoPlay
                                  loop
                                  muted
                                  playsInline></video>
                              ) : (
                                <img src={img} alt={heading} />
                              )}
                            </div>
                            <h2>{heading}</h2>
                            <p>{description}</p>
                            <button
                              type="button"
                              className="button"
                              onClick={() => signUp(toggleSignup)}>
                              {buttonText}
                            </button>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <TestimonialsSlider heading="Agencies love using ruttl" />
            <TrustedBy />
            <div className="container center">
              <div className="get-started">
                <h3>
                  Get started and save both time and money on your next web
                  project
                </h3>
                <a
                  href="https://web.ruttl.com"
                  type="button"
                  className="button">
                  Try it now for free
                </a>
              </div>
            </div>
            <NewFaq data={faqData} />
          </main>
        </>
      )}
    </Layout>
  );
};
export default ForAgencies;
